<template lang="pug">
nav#main-menu(ref="navbar")
  .container
    main-menu-desktop.is-hidden-mobile(:items="menuDesktopAndTabletItems")
    .main-menu-mobile-wrapper.is-hidden-tablet-only.is-hidden-desktop(v-show="menuOpen")
      main-menu-mobile(:visible="menuOpen", :items="menuItems")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainMenuComponent',

  components: {
    MainMenuDesktop: () => import('./MainMenuDesktop/MainMenuDesktop.vue'),
    MainMenuMobile: () =>
      import(/* webpackPrefetch: true */ './MainMenuMobile/MainMenuMobile.vue')
  },

  data: () => ({
    isPinned: false,
    menuItems: []
  }),

  async fetch() {
    const items = await import(
      `../../../utils/definitions/menus/${process.env.siteName}MainMenu.js`
    )
    this.menuItems = items.default.call(this)
  },

  computed: {
    ...mapGetters({
      menuOpen: 'app/menuOpen'
    }),

    menuDesktopAndTabletItems() {
      return this.menuItems.filter(
        (i) => i.id !== 'caa191f8-c3ba-4d30-bcca-45a2e0010d45'
      )
    }
  },

  watch: {
    menuOpen(state) {
      if (state) {
        this.disableScrolling()
        return
      }
      this.enableScrolling()
    }
  },

  mounted() {
    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
      { threshold: [1] }
    )

    observer.observe(this.$refs.navbar)
  },

  methods: {
    disableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      html.classList.add('stop-scrolling')
    },

    enableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      html.classList.remove('stop-scrolling')
    }
  }
}
</script>

<style lang="sass" scoped>
#main-menu
  width: 100%
  background-color: white
  top: -1px
  position: sticky
  z-index: 201
  +tablet-only
    z-index: 200
    transition: z-index 0s linear 0.2s, opacity 0.2s ease
    opacity: 1
    &:hover
      z-index: 201
      opacity: 1
      transition: z-index 0s linear 0s, opacity 0.2s ease
  +mobile
    z-index: 2001
  +desktop
    max-height: 35px
  &.is-pinned
    border: 1px solid $border-color
</style>
