<template lang="pug">
nav#main-menu(ref="navbar")
  main-menu-desktop.is-hidden-mobile(:items="menuDesktopAndTabletItems")
  .main-menu-mobile-wrapper.is-hidden-tablet(v-show="menuOpen")
    main-menu-mobile(:visible="menuOpen", :items="menuItems")
</template>

<script>
import MainMenu from '~~/components/Layout/MainMenu/MainMenu'

export default {
  name: 'MainMenuComponent',

  components: {
    MainMenuDesktop: () =>
      import('~/components/Layout/MainMenu/MainMenuDesktop/MainMenuDesktop'),
    MainMenuMobile: () =>
      import('~/components/Layout/MainMenu/MainMenuMobile/MainMenuMobile')
  },

  extends: MainMenu
}
</script>

<style lang="sass">
#main-menu
  .container
    +touch
      padding: unset
  .main-menu-mobile-wrapper
    +tablet-only
      position: absolute
      width: 100%
      height: 100vh
      background-color: rgba(0, 0, 0, 0.2)
</style>
